.titleContainer {
  padding: 18px 28px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  border-radius: 999px;
  background-color: var(--neutral-300);
  display: flex;
  align-items: center;
  gap: 6px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdownContent {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  width: 100%;
  box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.3);
  padding: 8px;
  border-radius: 8px;
  z-index: 1;
}

.dropdownContent__item {
  padding: 8px 18px;
  cursor: pointer;
}

.dropdown:hover .dropdownContent {
  display: block;
}

.dropdownContent__item:hover {
  background-color: var(--neutral-300);
}
