@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
@font-face {
  font-family: Rozanovageo;
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/RozanovaGEO-Demo-SemiBold.2bfde729.otf);
}
.Footer_w-form__vU_Bn {
  margin: 0 0 15px;
}
.Footer_w-container__pkKVv {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.Footer_w-container__pkKVv:before,
.Footer_w-container__pkKVv:after {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.Footer_w-container__pkKVv:after {
  clear: both;
}
.Footer_w-input__VQ0XG,
.Footer_w-select__qkM62 {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}

.Footer_w-input__VQ0XG::-moz-placeholder,
.Footer_w-select__qkM62::-moz-placeholder {
  color: #999;
  opacity: 1;
}
.Footer_w-input__VQ0XG::-webkit-input-placeholder,
.Footer_w-select__qkM62::-webkit-input-placeholder {
  color: #999;
}
.Footer_w-input__VQ0XG:focus,
.Footer_w-select__qkM62:focus {
  border-color: #3898ec;
  outline: 0;
}
.Footer_w-input__VQ0XG[disabled],
.Footer_w-select__qkM62[disabled],
.Footer_w-input__VQ0XG[readonly],
.Footer_w-select__qkM62[readonly],
fieldset[disabled] .Footer_w-input__VQ0XG,
fieldset[disabled] .Footer_w-select__qkM62 {
  cursor: not-allowed;
}
.Footer_w-input__VQ0XG[disabled]:not(.Footer_w-input-disabled__XPVU0),
.Footer_w-select__qkM62[disabled]:not(.Footer_w-input-disabled__XPVU0),
.Footer_w-input__VQ0XG[readonly],
.Footer_w-select__qkM62[readonly],
fieldset[disabled]:not(.Footer_w-input-disabled__XPVU0) .Footer_w-input__VQ0XG,
fieldset[disabled]:not(.Footer_w-input-disabled__XPVU0) .Footer_w-select__qkM62 {
  background-color: #eeeeee;
}
textarea.Footer_w-input__VQ0XG,
textarea.Footer_w-select__qkM62 {
  height: auto;
}
.Footer_input__TPfGf.Footer_footer-newsletter__v_2lp::-moz-placeholder {
  color: var(--neutral-500);
}
.Footer_input__TPfGf.Footer_footer-newsletter__v_2lp:-ms-input-placeholder {
  color: var(--neutral-500);
}
.Footer_input__TPfGf.Footer_footer-newsletter__v_2lp::placeholder {
  color: var(--neutral-500);
}
input.Footer_w-button__Jv57M {
  -webkit-appearance: button;
}
.Footer_w-layout-grid__Q6sXs {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}
.Footer_success-message__VfG3i {
  background-color: var(--primary-1);
  box-shadow: 0 5px 15px 0 var(--card-default-shadow-short);
  color: var(--neutral-100);
  text-align: center;
  border-radius: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}
.Footer_w-form-done__xc6mo {
  display: none;
  padding: 20px;
  text-align: center;
  background-color: #dddddd;
}

.Footer_footer__AxF0B {
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);
  font-family: 'Kumbh Sans', sans-serif;

  border-top: 1px solid var(--neutral-400);
  background-color: #297494;
  padding-top: 50px;
  padding-bottom: 0;
  font-size: 18px;
  width: 100%;
}

.Footer_container-default__SeqJL {
  max-width: 1209px;
  flex: 1 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}

.Footer_footer-content-top__pInV6 {
  justify-content: space-between;
  display: flex;
}

.Footer_footer-logo-container__zHRmU {
  transform-style: preserve-3d;
  transition-property: transform;
}

.Footer_footer-logo-container__zHRmU:hover {
  transform: scale3d(0.96, 0.96, 1.01);
}
.Footer_w-inline-block__UQgyu {
  max-width: 100%;
  display: inline-block;
}

.Footer_paragraph__vdNrG.Footer_footer-content-top-paragraph__QWt8B {
  max-width: 468px;
  color: var(--neutral-400);
  margin-bottom: 0;
}
.Footer_divider__d43dx {
  width: 100%;
  height: 1px;
  background-color: var(--neutral-300);
}

.Footer_divider__d43dx.Footer_footer-top__eVT6y {
  background-color: var(--neutral-100);

  margin-top: 50px;
  margin-bottom: 50px;
}

.Footer_divider__d43dx.Footer_footer-bottom__NSN8p {
  background-color: var(--neutral-100);
  margin-top: 50px;
  margin-bottom: 30px;
}

.Footer_footer-content__tVz_R {
  justify-content: space-between;
  margin-bottom: -26px;
  display: flex;
}
.Footer_footer-newsletter-content__fFMLN {
  max-width: 396px;
  flex: 1 1;
  margin-right: 30px;
}
.Footer_footer-newsletter-content-top__LOTJX {
  margin-bottom: 32px;
}

.Footer_image__CaUR_.Footer_footer-newsletter-icon__3981h {
  width: 72px;
  height: 72px;
  min-width: 72px;
  border-radius: 1000px;
  margin-bottom: 24px;
  overflow: hidden;
}

.Footer_title__f1zH8.Footer_h3-size__7yZ_j {
  font-family: Kumbh Sans, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5em;
}
.Footer_title__f1zH8.Footer_h3-size__7yZ_j.Footer_footer-newsletter__v_2lp {
  color: var(--neutral-100);
  margin-bottom: 12px;
  font-family: 'Rozanovageo', sans-serif;

  font-weight: 600;
}
.Footer_paragraph__vdNrG.Footer_footer-newsletter__v_2lp {
  color: var(--neutral-400);
  margin-bottom: 0;
}

.Footer_footer-newsletter-form-block__ooWR2 {
  margin-bottom: 0;
}

.Footer_footer-newsletter-form__KdNkW {
  align-items: center;
  display: flex;
  position: relative;
}
.Footer_input__TPfGf {
  min-height: 65px;
  background-color: var(--neutral-200);
  color: var(--neutral-800);
  border: 1px solid rgba(0, 0, 0, 0);
  border-radius: 100px;
  margin-bottom: 0;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
  line-height: 1.111em;
  transition: border-color 0.3s, color 0.3s;
}

.Footer_input__TPfGf:hover {
  border-color: var(--neutral-400);
}

.Footer_input__TPfGf:focus,
.Footer_input__TPfGf.Footer_-wfp-focus__daZjX {
  border-color: var(--neutral-400);
  color: var(--neutral-800);
}

.Footer_input__TPfGf::-moz-placeholder {
  color: var(--neutral-600);
}

.Footer_input__TPfGf:-ms-input-placeholder {
  color: var(--neutral-600);
}

.Footer_input__TPfGf::placeholder {
  color: var(--neutral-600);
}
.Footer_input__TPfGf.Footer_footer-newsletter__v_2lp {
  min-height: 71px;
  background-color: var(--neutral-100);
  padding-left: 23px;
  padding-right: 125px;
}

.Footer_input__TPfGf.Footer_footer-newsletter__v_2lp::-ms-input-placeholder {
  color: var(--neutral-500);
}

.Footer_error-message__35D5M {
  box-shadow: 0 5px 15px 0 var(--card-default-shadow-short);
  color: var(--neutral-100);
  text-align: center;
  background-color: #fc5457;
  border-radius: 20px;
  align-self: stretch;
  margin-top: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.Footer_w-form-fail__tMBou {
  display: none;
  margin-top: 10px;
  padding: 10px;
  background-color: #ffdede;
}
.Footer_footer-nav-main-wrapper__FqYmF {
  width: 100%;
  max-width: 659px;
  flex: 1 1;
  justify-content: space-between;
  display: flex;
}
.Footer_footer-nav-wrapper__udOE_.Footer_pages__hAE_S {
  width: 100%;
  max-width: 372px;
  margin-right: 30px;
}
.Footer_footer-nav-title__TllyN {
  color: var(--neutral-100);
  margin-bottom: 40px;
  font-weight: 700;
  line-height: 1.111em;
}
.Footer_footer-nav-content__Aos_S {
  justify-content: space-between;
  display: flex;
}
.Footer_footer-nav__8XYQu {
  margin-bottom: 0;
  margin-right: 30px;
  padding-left: 0;
  list-style-type: none;
}
.Footer_footer-nav-item__0NEak {
  margin-bottom: 26px;
  line-height: 1em;
  color: var(--neutral-200);
}
.Footer_footer-nav-link__lJbD0 {
  color: var(--neutral-400);
  white-space: nowrap;
  line-height: 1.111em;
  text-decoration: none;
  transition: color 0.35s;
}
.Footer_footer-nav-link__lJbD0:hover {
  color: var(--secondary-3);
}
.Footer_footer-content-bottom__kFd7a {
  flex-wrap: wrap-reverse;
  /* justify-content: space-between; */
  align-items: center;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 24px;
}

.Footer_footer-social-media-grid___uBR3 {
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto auto auto;
  margin-bottom: 24px;
}

.Footer_footer-social-media-link__G_rXj {
  width: 36px;
  min-height: 36px;
  min-width: 36px;
  background-color: var(--neutral-600);
  color: var(--neutral-200);
  border-radius: 1000px;
  justify-content: center;
  align-items: center;
  /* font-family: Icons Education X Template, sans-serif; */
  text-decoration: none;
  transition-property: background-color;
  display: flex;
}

.Footer_footer-social-media-link__G_rXj:hover {
  background-color: var(--primary-1);
  color: var(--neutral-100);
}

.Footer_footer-social-media-link__G_rXj.Footer_icon-15px__KWeQd {
  font-size: 15px;
}

.Footer_footer-social-media-link__G_rXj.Footer_icon-16px__au7P8 {
  font-size: 16px;
}

.Footer_footer-social-media-link__G_rXj.Footer_icon-17px__uqU05 {
  font-size: 17px;
}

.Footer_button-primary-header__V3mcF {
  background-color: #024d6d;
  color: white;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  transform-style: preserve-3d;
  border-radius: 1000px;
  padding: 24px 40px;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.125em;
  transition: transform 0.3s, background-color 0.3s;
}

.Footer_button-primary-header__V3mcF.Footer_footer-newsletter__v_2lp {
  padding: 15px 24px;
  font-size: 14px;
  line-height: 1.143em;
  position: absolute;
  right: 10px;
}

@media screen and (max-width: 991px) {
  .Footer_w-container__pkKVv {
    max-width: 728px;
  }
  .Footer_container-default__SeqJL {
    flex-direction: column;
    align-items: stretch;
  }

  .Footer_footer-content-top__pInV6 {
    flex-wrap: wrap;
  }
  .Footer_footer-logo-container__zHRmU {
    margin-bottom: 20px;
    margin-right: 30px;
  }
  .Footer_divider__d43dx.Footer_footer-top__eVT6y {
    margin-top: 50px;
    margin-bottom: 80px;
  }

  .Footer_divider__d43dx.Footer_footer-bottom__NSN8p {
    margin-top: 96px;
  }
  .Footer_footer-content__tVz_R {
    flex-direction: column;
  }
  .Footer_footer-newsletter-content__fFMLN {
    max-width: 600px;
    margin-bottom: 60px;
    margin-right: 0;
  }
  .Footer_footer-newsletter-content-top__LOTJX {
    align-items: flex-start;
    display: flex;
  }
  .Footer_image__CaUR_.Footer_footer-newsletter-icon__3981h {
    margin-right: 25px;
  }
  .Footer_title__f1zH8.Footer_h3-size__7yZ_j.Footer_footer-newsletter__v_2lp {
    margin-bottom: 10px;
  }
  .Footer_paragraph__vdNrG.Footer_footer-newsletter__v_2lp {
    max-width: 380px;
  }
  .Footer_footer-nav-main-wrapper__FqYmF {
    max-width: 730px;
  }
  .Footer_divider__d43dx.Footer_footer-bottom__NSN8p {
    margin-top: 96px;
  }

  .Footer_image__CaUR_.Footer_footer-newsletter-icon__3981h {
    margin-right: 25px;
  }
}

@media screen and (max-width: 767px) {
  .Footer_footer-content-top__pInV6 {
    flex-flow: column;
    align-items: flex-start;
  }
  .Footer_footer-logo-container__zHRmU {
    margin-right: 0;
  }
  .Footer_divider__d43dx.Footer_footer-top__eVT6y {
    margin-top: 40px;
    margin-bottom: 60px;
  }

  .Footer_divider__d43dx.Footer_footer-bottom__NSN8p {
    margin-top: 77px;
  }
  .Footer_footer-newsletter-content-top__LOTJX {
    margin-bottom: 28px;
  }
  .Footer_image__CaUR_.Footer_footer-newsletter-icon__3981h {
    margin-right: 20px;
  }
  .Footer_footer-nav-main-wrapper__FqYmF {
    flex-wrap: wrap;
  }
  .Footer_footer-nav-wrapper__udOE_.Footer_pages__hAE_S {
    margin-bottom: 40px;
  }
  .Footer_footer-nav-title__TllyN {
    margin-bottom: 35px;
  }
  .Footer_footer-nav-content__Aos_S {
    flex-wrap: wrap;
  }
  .Footer_divider__d43dx.Footer_footer-bottom__NSN8p {
    margin-top: 77px;
  }
  .Footer_footer-social-media-grid___uBR3 {
    margin-bottom: 20px;
  }
  .Footer_image__CaUR_.Footer_footer-newsletter-icon__3981h {
    margin-right: 20px;
  }
}

@media screen and (max-width: 479px) {
  .Footer_w-container__pkKVv {
    max-width: none;
  }
  .Footer_container-default__SeqJL {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -o-object-fit: fill;
       object-fit: fill;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    position: static;
    overflow: visible;
  }
  .Footer_title__f1zH8.Footer_h3-size__7yZ_j {
    font-size: 20px;
  }

  .Footer_footer-logo-container__zHRmU {
    margin-bottom: 15px;
  }
  .Footer_divider__d43dx.Footer_footer-top__eVT6y {
    margin-bottom: 50px;
  }

  .Footer_divider__d43dx.Footer_footer-bottom__NSN8p {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .Footer_footer-newsletter-content__fFMLN {
    margin-bottom: 50px;
  }
  .Footer_footer-newsletter-content-top__LOTJX {
    flex-direction: column;
  }
  .Footer_image__CaUR_.Footer_footer-newsletter-icon__3981h {
    margin-bottom: 20px;
  }
  .Footer_footer-newsletter-form__KdNkW {
    flex-direction: column;
    align-items: stretch;
  }
  .Footer_input__TPfGf {
    padding-left: 15px;
    padding-right: 15px;
  }
  .Footer_input__TPfGf.Footer_footer-newsletter__v_2lp,
  .Footer_input__TPfGf.Footer_newsletter__1K68L {
    min-height: 65px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .Footer_button-primary-header__V3mcF.Footer_footer-newsletter__v_2lp {
    min-height: 65px;
    position: static;
  }
  .Footer_footer-nav-wrapper__udOE_.Footer_pages__hAE_S {
    margin-right: 20px;
  }
  .Footer_footer-nav__8XYQu {
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
  }
  .Footer_divider__d43dx.Footer_footer-bottom__NSN8p {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .Footer_footer-content-bottom__kFd7a {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }
  .Footer_footer-social-media-grid___uBR3 {
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(auto-fit, 36px);
    align-self: stretch;
    display: flex;
  }
  .Footer_image__CaUR_.Footer_footer-newsletter-icon__3981h {
    margin-bottom: 20px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Kumbh+Sans:wght@100..900&display=swap');
@font-face {
  font-family: Rozanovageo;
  src: url(webpack:///mini-css-extract-plugin//_next/static/media/RozanovaGEO-Demo-SemiBold.2bfde729.otf);
}

.Navbar_w-nav__CPqSM {
  position: relative;
  background: #dddddd;
  z-index: 1000;
}
.Navbar_w-nav__CPqSM:before,
.Navbar_w-nav__CPqSM:after {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.Navbar_w-nav__CPqSM:after {
  clear: both;
}
.Navbar_w-nav-brand__56hQ5 {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333333;
}
.Navbar_w-nav-link__vHfY4 {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-decoration: none;
  color: #222222;
  padding: 20px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
.Navbar_w-nav-link__vHfY4.Navbar_w--current__ioSCP {
  color: #0082f3;
}
.Navbar_w-nav-menu__Ii4hP {
  position: relative;
  float: right;
}
/* }
[data-nav-menu-open] {
  display: block !important;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #c8c8c8;
  text-align: center;
  overflow: visible;
  min-width: 200px;
} */
.Navbar_w--nav-link-open__Fm0XJ {
  display: block;
  position: relative;
}
.Navbar_w-nav-overlay__osBLy {
  position: absolute;
  overflow: hidden;
  display: none;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
}
.Navbar_w-nav-overlay__osBLy [data-nav-menu-open] {
  top: 0;
}
.Navbar_w-nav__CPqSM[data-animation='over-left'] .Navbar_w-nav-overlay__osBLy {
  width: auto;
}
.Navbar_w-nav__CPqSM[data-animation='over-left'] .Navbar_w-nav-overlay__osBLy,
.Navbar_w-nav__CPqSM[data-animation='over-left'] [data-nav-menu-open] {
  right: auto;
  z-index: 1;
  top: 0;
}
.Navbar_w-nav__CPqSM[data-animation='over-right'] .Navbar_w-nav-overlay__osBLy {
  width: auto;
}
.Navbar_w-nav__CPqSM[data-animation='over-right'] .Navbar_w-nav-overlay__osBLy,
.Navbar_w-nav__CPqSM[data-animation='over-right'] [data-nav-menu-open] {
  left: auto;
  z-index: 1;
  top: 0;
}
.Navbar_w-nav-button__l1EFW {
  position: relative;
  float: right;
  padding: 18px;
  font-size: 24px;
  display: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.Navbar_w-nav-button__l1EFW:focus {
  outline: 0;
}
.Navbar_w-nav-button__l1EFW.Navbar_w--open__7rGHG {
  background-color: #c8c8c8;
  color: white;
}
.Navbar_w-nav__CPqSM[data-collapse='all'] .Navbar_w-nav-menu__Ii4hP {
  display: none;
}
.Navbar_w-nav__CPqSM[data-collapse='all'] .Navbar_w-nav-button__l1EFW {
  display: block;
}
.Navbar_w--nav-dropdown-open__X1Fpi {
  display: block;
}
.Navbar_w--nav-dropdown-toggle-open__1hPcj {
  display: block;
}
.Navbar_w--nav-dropdown-list-open__PMVhB {
  position: static;
}

.Navbar_w-container__vQVrO {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}
.Navbar_w-container__vQVrO:before,
.Navbar_w-container__vQVrO:after {
  content: ' ';
  display: table;
  grid-column-start: 1;
  grid-row-start: 1;
  grid-column-end: 2;
  grid-row-end: 2;
}
.Navbar_w-container__vQVrO:after {
  clear: both;
}
.Navbar_w-nav-link__vHfY4.Navbar_w--current__ioSCP {
  color: #0082f3;
}

.Navbar_w-nav-brand__56hQ5 {
  position: relative;
  float: left;
  text-decoration: none;
  color: #333333;
}
.Navbar_header__xl0ui {
  --neutral-600: #62778f;
  --neutral-800: #0f437f;
  --neutral-700: #1e3d60;
  --primary-1: #064ea4;
  --secondary-3: #f99d77;
  --card-default-shadow-short: rgba(30, 61, 96, 0.03);
  --neutral-100: white;
  --secondary-1: #999;
  --secondary-2: #fcdf69;
  --neutral-500: #8096b0;
  --neutral-400: #becad7;
  --neutral-300: #e7eaee;
  --neutral-200: #f9fafb;
  --jkh: 100000px;
  --dark-slate-grey: #024d6d;
  --cadet-blue: #079ba9;
  --header-shadow: rgba(6, 43, 86, 0.04);
  --card-default-shadow-long: rgba(30, 61, 96, 0.05);
  --crimson: #db2a47;
  --shadow-1: rgba(3, 14, 26, 0.39);

  font-family: 'Kumbh Sans', sans-serif;
  font-size: 18px;
  box-shadow: 0 6px 12px 0 var(--header-shadow);
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  display: block;
}

.Navbar_header-navigation__01iU8 {
  flex: 0 1 auto;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
}
.Navbar_split-content__u6cs7.Navbar_header-left__A11zk {
  align-items: center;
  display: flex;
}
.Navbar_header-wrapper__LNLoT {
  width: 100%;
  max-width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 12px 18px;
  grid-gap: 24px;
  gap: 24px;
}

.Navbar_container-default__OgK1s {
  max-width: 1209px;
  flex: 1 1;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
.Navbar_container-default__OgK1s.Navbar_header-container__1YMfW {
  width: auto;
  background-color: var(--neutral-100);
  padding-top: 0;
  padding-bottom: 0;
}

.Navbar_nav-link__eqPpM {
  color: var(--neutral-700);
  font-weight: 700;
  line-height: 1.111em;
  text-decoration: none;
  transition: color 0.35s;
}
.Navbar_nav-link__eqPpM:hover {
  color: var(--secondary-3);
}
.Navbar_nav-link__eqPpM.Navbar_w--current__ioSCP {
  margin-left: auto;
  margin-right: auto;
}

.Navbar_nav-link__eqPpM.Navbar_dropdown__zfLlT {
  padding: 0;
}

.Navbar_nav-link__eqPpM.Navbar_dropdown__zfLlT:hover {
  color: var(--secondary-3);
}

.Navbar_brand__ZgDK2 {
  transform-style: preserve-3d;
  padding-left: 0;
  transition-property: transform;
  padding-top: 6px;
}

.Navbar_brand__ZgDK2:hover {
  transform: scale3d(0.96, 0.96, 1.01);
}

.Navbar_nav-menu__lPIPw {
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.Navbar_nav-item-wrapper__Kq09H {
  margin-bottom: 0;
  margin-right: 40px;
}

@media screen and (max-width: 991px) {
  .Navbar_w-nav__CPqSM .Navbar_w-nav-menu__Ii4hP {
    display: none;
  }
  .Navbar_w-nav__CPqSM .Navbar_w-nav-button__l1EFW {
    display: block;
  }
  .Navbar_container-default__OgK1s {
    flex-direction: column;
    align-items: stretch;
  }
  .Navbar_split-content__u6cs7.Navbar_header-right__c0pFK,
  .Navbar_split-content__u6cs7.Navbar_header-left__A11zk {
    z-index: 99;
    position: relative;
  }
  .Navbar_nav-menu__lPIPw {
    width: 100%;
    background-color: var(--neutral-100);
    padding-top: 120px;
    padding-bottom: 36px;
  }
  .Navbar_header-navigation__01iU8 {
    flex-direction: column;
    align-items: stretch;
    margin-left: 24px;
    margin-right: 24px;
  }
  .Navbar_nav-item-wrapper__Kq09H {
    margin-right: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
  }
  .Navbar_nav-link__eqPpM {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .Navbar_w-nav-brand__56hQ5 {
    padding-left: 10px;
  }
  .Navbar_w-nav__CPqSM[data-collapse='small'] .Navbar_w-nav-menu__Ii4hP {
    display: none;
  }
  .Navbar_w-nav__CPqSM[data-collapse='small'] .Navbar_w-nav-button__l1EFW {
    display: block;
  }
  .Navbar_w-nav-brand__56hQ5 {
    padding-left: 10px;
  }
  .Navbar_w-nav-brand__56hQ5 {
    padding-left: 10px;
  }
  .Navbar_w-nav-brand__56hQ5 {
    padding-left: 10px;
  }
  .Navbar_brand__ZgDK2 {
    margin-right: 20px;
  }
  .Navbar_nav-item-wrapper__Kq09H {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .Navbar_nav-link__eqPpM {
    font-size: 30px;
  }
}
@media screen and (max-width: 479px) {
  .Navbar_w-nav__CPqSM[data-collapse='tiny'] .Navbar_w-nav-menu__Ii4hP {
    display: none;
  }
  .Navbar_w-nav__CPqSM[data-collapse='tiny'] .Navbar_w-nav-button__l1EFW {
    display: block;
  }
  .Navbar_container-default__OgK1s {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -o-object-fit: fill;
       object-fit: fill;
    flex-direction: column;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    justify-content: flex-start;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    position: static;
    overflow: visible;
  }
  .Navbar_nav-menu__lPIPw {
    padding-top: 110px;
  }
  .Navbar_header-navigation__01iU8 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .Navbar_nav-link__eqPpM {
    font-size: 1em;
  }
}

.Navbar_buttonsContainer__Cuy_u {
  display: flex;
  grid-gap: 6px;
  gap: 6px;
}

.Navbar_buttonsContainer__Cuy_u .Navbar_primaryButton__NbFAG {
  background-color: var(--cadet-blue);
}

.HoverDropdown_titleContainer__40tgy {
  padding: 18px 28px;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  border-radius: 999px;
  background-color: var(--neutral-300);
  display: flex;
  align-items: center;
  grid-gap: 6px;
  gap: 6px;
}

.HoverDropdown_dropdown__izqlM {
  position: relative;
  display: inline-block;
}

.HoverDropdown_dropdownContent__pJvpA {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  width: 100%;
  box-shadow: 0px 8px 16px 4px rgba(0, 0, 0, 0.3);
  padding: 8px;
  border-radius: 8px;
  z-index: 1;
}

.HoverDropdown_dropdownContent__item___cy3e {
  padding: 8px 18px;
  cursor: pointer;
}

.HoverDropdown_dropdown__izqlM:hover .HoverDropdown_dropdownContent__pJvpA {
  display: block;
}

.HoverDropdown_dropdownContent__item___cy3e:hover {
  background-color: var(--neutral-300);
}

